import React from 'react';

export type AuthContextProps = {
    tokenId: string|undefined,
}
export const DefaultAuthContextProps = {
    tokenId: undefined,
}
const AuthContext = React.createContext(DefaultAuthContextProps);

export const AuthProvider = AuthContext.Provider;

export default AuthContext
