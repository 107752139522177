import React, { useEffect, useState } from 'react';
import { appendErrors, Control, Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import {Link, MemoryRouter, Route, Switch} from 'react-router-dom';
import { useHistory, useParams } from "react-router";
import PlayerProfile from "./PlayerProfile";
import PlayerGamesArchives from "./PlayerGamesArchives";

type PlayerGamesLoadingProps = {
  id: string
}




export default function PlayerGamesLoading() {
  const [isPlayerProfileLoading, setIsPlayerProfileLoading] = useState(true);
  const [playerProfile, setPlayerProfile] = useState({});
  const [errors, setErrors] = useState();
  const [playerGameArchives, setPlayerGameArchives] = useState<Array<string>>();
  const {id} = useParams<PlayerGamesLoadingProps>();
  // https://api.chess.com/pub/player/{}/games/archives
  // useEffect(() => {
  //     const gameArchiveUrl = "https://api.chess.com/pub/player/" + id + "/games/archives";
  //     console.log("gameArchiveUrl = " + gameArchiveUrl);
  //     fetch(
  //       gameArchiveUrl, 
  //       {
  //         method: 'GET',
  //         headers: {
  //           Accept: 'application/json',
  //         },
  //       })
  //       .then(response => {
  //           if (response.ok) {
  //               response.json().then(json => {
  //                   console.log(JSON.stringify(json.archives));
  //                   setPlayerGameArchives(json.archives);
  //               });
  //           } else {
  //               console.log("response is not ok");
  //           }
  //       })
  //       .catch(error => {
  //           console.log("caught error calling for game archive: " + error);
  //           setErrors(error);
  //       });
  // }, [id]);

  useEffect(() => {
    const url = "https://api.chessbuff.me/player/" + id;
    // const url = "http://localhost:8001/player/" + id;

    fetch(
      url, 
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    .then(response => {
      if (response.ok) {
        console.log("response is ok");
        response.json().then(json => {
          console.log(json);
          setPlayerProfile(json);
          setIsPlayerProfileLoading(false);
        });
      } else {
        console.log("response is not ok");
      }
    })
    .catch(error => {
      console.log("caught error " + JSON.stringify(error));
    });
  }, [id]);

  if (!playerProfile) {
      return (<>Spinning</>);
  }
  const playerProfileComponent = PlayerProfile(playerProfile);
  // const playerGameArchivesComponent = PlayerGamesArchives(id, playerGameArchives);
  
  return (
      <>
      <div>{playerProfileComponent}</div>
      {/* <div>{playerGameArchivesComponent}</div> */}
      
      </>

  );
}
