import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

type ChessComImportFormProps = {
    player_id: string,
}

export default function ChessComImportForm(player_id: any) {
    // const { register, handleSubmit, errors } = useForm<ChessComImportFormProps>({
    //     mode: 'onSubmit',
    //     reValidateMode: 'onChange',
    //     defaultValues: {},
    //     shouldFocusError: true,
    //     shouldUnregister: true,
    // });
    // const history = useHistory();
    // const onSubmit = handleSubmit((data, event) => {
    //     console.log("importing data from chess.com: " + JSON.stringify(data.player_id));
    //     history.push(
    //         {pathname: "/player/" + data.player_id + "/imported"}
    //         );
    // });
    // return (<>
    //     <form onSubmit={onSubmit}>
    //         <input type="hidden" name="player_id" value={player_id} ref={register({required: "player is required (min 3)", minLength: 3})}/>
    //         <div><button type="submit">Import game data from chess.com</button></div>
    //     </form>
    // </>);
    return (<> hi </>);
}