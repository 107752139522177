import React, { useEffect, useState } from 'react';
import { appendErrors, Control, Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import {Link, MemoryRouter, Route, Switch} from 'react-router-dom';
import { useHistory, useParams } from "react-router";
import PlayerProfile from "./PlayerProfile";

type PlayerProfileLoadingProps = {
  id: string
}




export default function PlayerProfileLoading() {
  const [isPlayerProfileLoading, setIsPlayerProfileLoading] = useState(true);
  const [playerProfile, setPlayerProfile] = useState({});
  const {id} = useParams<PlayerProfileLoadingProps>();
  useEffect(() => {
    const url = "https://api.chessbuff.me/player/" + id;
    // const url = "http://localhost:8001/player/" + id;

    fetch(
      url, 
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    .then(response => {
      if (response.ok) {
        console.log("response is ok");
        response.json().then(json => {
          console.log(json);
          setPlayerProfile(json);
          setIsPlayerProfileLoading(false);
        });
      } else {
        console.log("response is not ok");
      }
    })
    .catch(error => {
      console.log("caught error " + JSON.stringify(error));
    });
  }, []);

  let component = (<>Spinning</>);
  if (!isPlayerProfileLoading) {
    component = PlayerProfile(playerProfile);
  }
  
  return (
      <>
      {component}
      </>
  );
}
