import React, { useEffect, useState } from 'react';
import { appendErrors, Control, Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import {Link, MemoryRouter, Route, Switch} from 'react-router-dom';
import { useHistory } from "react-router";
type PlayerLookupFormProps = {
  player: string
}



export default function SearchForm() {
  const { register, handleSubmit, errors } = useForm<PlayerLookupFormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const history = useHistory();
  const [data, setData] = useState(null);
  const [player, setPlayer] = useState({});
  
  const onSubmit = handleSubmit((data, event) => {
    console.log(JSON.stringify(data));
    history.push({
      pathname: "/player/" + data.player
    });
  });
  return (
      <form onSubmit={onSubmit}>
        <div className="App">
          <header className="App-header">
            <input type="text" placeholder="player" name="player" ref={register({required: "player is required (min 3)", minLength: 3})}/>
            {errors.player && <div className="error">player is required (min 3)</div>}
            <button type="submit">Lookup Player</button>
          </header>
        </div>
      </form>
  );
}
