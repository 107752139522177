import {useContext} from 'react';
import { useGoogleLogin } from 'react-google-login';
import AuthContext from './features/auth/auth';


interface ProtectedRouteProps {
    googleOAuthClientId: string,
 }

 function onSuccess(response: any) {
     console.log("success : " + response);
 }

 function onFailure() {
     console.log("failure");
 } 

export default function ProtectedRoute(props: React.PropsWithChildren<ProtectedRouteProps>) {
    const authContext = useContext(AuthContext);
    const login = (<div>You need to sign in!</div>)
    if (authContext.tokenId === undefined) {
        return (
            <div>{login}</div>
        );
    }
    return (<div>{props.children}</div>);
}