import React, { useEffect, useState, createContext } from 'react';
import logo from './logo.svg';
import './App.css';
import { appendErrors, Control, Controller, useForm } from "react-hook-form";
import { TextField, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { Link, MemoryRouter, Route, Switch } from 'react-router-dom';
import SearchForm from "./features/player/PlayerLookupForm";
import PlayerProfileLoading from "./features/player/PlayerProfileLoading";
import PlayerGamesLoading from "./features/player/PlayerGamesLoading";
import ImportingData from './features/player/ImportingData';
import UploadPGNForm from './features/pgn/UploadPGNForm';
import Practice from './features/practice/Practice';
import GoogleLogin from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import ProtectedRoute from './ProtectedRoute';
import AuthContext, { AuthContextProps, DefaultAuthContextProps } from './features/auth/auth';

function googleAuthFailure(response: any) {
  console.log("googleAuthFailure callback");
  console.log(response);
}

function googleLogoutSuccess() {
  console.log("logged out");
}


function googleLogoutFailure() {
  console.log("logged out failed");
}


const LocalGoogleOAuthClientId = "688909009321-2dgj4bhb9d55cgnc7sfatc1qk70it8tn.apps.googleusercontent.com";
const ProdGoogleOAuthClientId = "688909009321-7uilakhodlsf4j6lgiqajhh73ancdue5.apps.googleusercontent.com";
type User = {
  tokenId: string | undefined,
}
const defaultUser: User = {
  tokenId: undefined,
}

function App() {
  const googleOAuthClientId = ProdGoogleOAuthClientId;
  let [authContextProps, setAuthContextProps] = useState(DefaultAuthContextProps);
  let loginSuccess = (response: any) => {
    console.log("loginSuccess callback");
    const { tokenId } = response;
    let authContextProps = ({
      tokenId: tokenId,
    });
    console.log(authContextProps);
    setAuthContextProps(authContextProps);
  }

  return (
    <div>
      <Switch>
        <AuthContext.Provider value={authContextProps}>
          <Route exact path="/">
            <Link to="/search">Lookup Player Profile</Link>
            <div><Link to="/pgn/upload">Upload PGN</Link></div>
            <div>
              <GoogleLogin
                clientId={googleOAuthClientId}
                buttonText="Google Login"
                onSuccess={loginSuccess}
                onFailure={googleAuthFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true} />
            </div>
            <div>
              <GoogleLogout
                clientId={googleOAuthClientId}
                buttonText="Google Logout"
                onLogoutSuccess={googleLogoutSuccess}
                onFailure={googleLogoutFailure} />
            </div>
            <div><Link to="/practice">Practice</Link></div>
          </Route>
          <Route exact path="/pgn/upload">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}><UploadPGNForm /></ProtectedRoute>
          </Route>
          <Route exact path="/practice">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}>
              <Practice />
            </ProtectedRoute>
          </Route>
          <Route exact path="/player/:id">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}><PlayerProfileLoading /></ProtectedRoute>
          </Route>
          <Route path="/player/:id/games">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}><PlayerGamesLoading /></ProtectedRoute>
          </Route>
          <Route path="/player/:id/imported">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}><ImportingData /></ProtectedRoute>
          </Route>
          <Route exact path="/search">
            <ProtectedRoute googleOAuthClientId={googleOAuthClientId}><SearchForm /></ProtectedRoute>
          </Route>
        </AuthContext.Provider>
      </Switch>
    </div>
  );
}

export default App;
