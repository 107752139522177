import React, { useEffect, useState } from 'react';
import { appendErrors, Control, Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { Link, MemoryRouter, Route, Switch, useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import ChessComImportForm from './ChessComImportForm';

export default function PlayerProfile(player: any) {

    if (player.title === undefined || player.title === null) {
        player.title = "None";
    }

    if (player.avatar_url === undefined || player.avatar_url === null || player.avatar_url.length <= 1) {
        player.avatar_url = "https://store.playstation.com/store/api/chihiro/00_09_000/container/TR/en/999/EP4037-SLES51630_00-AVPLAYITCH000002/1550743721000/image?w=240&h=240&bg_color=000000&opacity=100&_version=00_09_000";
    }
    let importForm = ChessComImportForm(player.username);



    return (
        <>
            <div>Username: {player.username}</div>
            <div><img src={player.avatar_url} /></div>
            <div>Id: {player.id}</div>
            <div>Profile Url : <a href={player.profile_url}>Chess.com profile</a></div>
            <div>Player Id: {player.player_id}</div>
            <div>Title: {player.title}</div>
            <div>{importForm}</div>
        </>
    );
}
